import React from 'react'
import classNames from 'classnames'
import propTypes from 'prop-types'
import { Box, Grid, Typography } from '@material-ui/core'
import useStyles from './style'
import SketchImg from '../../../static/images/sketch-1.png'
const ServiceList = props => {
  const classes = useStyles()
  const { withTitle, serviceData, isLastElement } = props

  return (
    <Box mb="80px">
      <Grid container className={classes.serviceGridWrapper}>
        <Grid item md={5} sm={6} xs={12}>
          <Box className={classes.imgWrapper}>
            <img
              src={
                serviceData.serviceImage.url
                  ? serviceData.serviceImage.url
                  : serviceData.serviceImage
              }
              alt="Sketch 1"
              className={classes.sketchImage}
            />
          </Box>
        </Grid>
        <Grid item md={7} sm={6} xs={12}>
          <Box mb="35px">
            <Typography variant="h4" className={classes.heading}>
              {serviceData.serviceName}
            </Typography>
          </Box>
          <Box mb="35px">
            <Typography variant="body2" className={classes.description}>
              {serviceData.serviceDesc}
            </Typography>
          </Box>
          {/* Service Information Grid */}
          <Box mb="20px" className={classes.serviceDetailArea}>
            <Grid
              container
              spacing={4}
              className={classNames(
                classes.serviceDetailContainer,
                isLastElement ? classes.removeBorderBottom : undefined
              )}
            >
              {serviceData.serviceList.map((list, index) => (
                <Grid
                  item
                  md={6}
                  sm={6}
                  xs={12}
                  className={classes.serviceDetailWrapper}
                  key={index}
                >
                  <Box mb="20px">
                    <Typography variant="h5" className={classes.subHeading}>
                      {list.serviceTitle}
                    </Typography>
                  </Box>
                  <Box mb="20px">
                    <Typography variant="body2" className={classes.information}>
                      {list.serviceInfo}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default ServiceList

ServiceList.propTypes = {
  withTitle: propTypes.bool,
}
