import { makeStyles } from '@material-ui/core/styles'

const sectionPadding = '7.5rem'
const mainPadding = 10
const useStyles = makeStyles(theme => ({
  root: {
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: sectionPadding,
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
      padding: mainPadding,
    },
    color: '#000',
  },
}))

export default useStyles
