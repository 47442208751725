import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    borderBottom: '0.094rem solid rgba(0, 0, 0, .05)',
  },
  serviceTab: {
    // padding:"1.563rem 0 0 0",
    position: 'sticky',
    padding: '30px 10px 5px 10px',
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
      overflowX: 'scroll',
    },
  },
  active: {
    borderBottom: '5px solid #D28F00',
  },
  serviceLinks: {
    padding: '10px 5px',
    cursor: 'pointer',
    minWidth: '200px',
    margin: '0 5px',
    textAlign: 'center',
    '&:hover': {
      borderBottom: '5px solid #D28F00',
    },
  },
  serviceTabList: {
    padding: '0 5px',
    fontFamily: 'Inter-semiBold',
    textTransform: 'capitalize',
  },
}))

export default useStyles
