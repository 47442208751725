import { makeStyles } from '@material-ui/core/styles'

const mainPadding = '0% 0.625rem'
const mainMargin = 16

const normalFontSize = 16
const normalLineHeight = '1.5rem'
const gridMaxWidth = 325
const useStyles = makeStyles(theme => ({
  serviceGridWrapper: {
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
      padding: mainPadding,
    },
    height: 'auto',
  },
  /* Images */
  imgWrapper: {
    width: '90%',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: 'auto',
      marginBottom: mainMargin,
    },
  },
  sketchImage: {
    width: '100%',
    display: 'block',
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      width: '100%',
      height: '100%',
    },
  },
  /* Grid Detail Wrapper */
  serviceDetailArea: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      // justifyContent: "center",
      alignItems: 'center',
      textAlign: 'left',
    },
  },
  serviceDetailContainer: {
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '2.5rem',
    },
    paddingBottom: '5rem',
    width: '100%',
    borderBottom: '1.5px solid rgba(0, 0, 0, 0.1)',
  },
  removeBorderBottom: {
    border: 'none',
  },
  serviceDetailWrapper: {
    // maxWidth: 325,
  },
  /* Typography */
  heading: {
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
      textAlign: 'center',
    },
    maxWidth: '100%',
    fontSize: 48,
    fontFamily: 'roosevelt02',
  },
  description: {
    [theme.breakpoints.up('md')]: {
      width: 485,
    },
    fontSize: normalFontSize,
    lineHeight: normalLineHeight,
    color: '#000',
    opacity: '80%',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'left',
    },
  },
  subHeading: {
    maxWidth: gridMaxWidth,
    fontWeight: 600,
    fontSize: 24,
    lineHeight: '1.875rem',
    textTransform: 'capitalize',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'left',
    },
  },
  title: {
    maxWidth: gridMaxWidth,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'left',
    },
    fontSize: normalFontSize,
    fontWeight: 600,
    lineHeight: normalLineHeight,
  },
  information: {
    maxWidth: gridMaxWidth,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'left',
    },
    fontSize: normalFontSize,
    lineHeight: normalLineHeight,
    opacity: '0.8',
  },
}))

export default useStyles
