import React, { useEffect, useRef } from 'react'
import { Link } from 'react-scroll'
import { Paper, Tabs, Tab, Button, Box } from '@material-ui/core'
import { Link as AnchorLink } from 'react-scroll'
import useStyles from './style'
const ServiceTab = props => {
  const classes = useStyles()
  const { handleActiveService, datas } = props
  const [value, setValue] = React.useState(0)

  // const itemRef = useRef(null);
  const handleChange = (event, newValue) => {
    // handleActiveService(newValue)
    setValue(newValue)
  }
  // useEffect(() => {
  //   console.log('itemRef', itemRef)
  //   if (itemRef && itemRef.current) {
  //     window.scrollTo({
  //       top: itemRef.current.offsetTop - 50,
  //       behavior: "smooth"
  //     });
  //   }
  // });
  return (
    <Paper className={classes.root} elevation={0}>
      <Box
        display="flex"
        justifyContent="space-between"
        className={classes.serviceTab}
      >
        {datas.map((data, index) => (
          <AnchorLink
            className={classes.serviceLinks}
            activeClass={classes.active}
            to={`service-${index}`}
            smooth={true}
            duration={500}
            spy={true}
            offset={-100}
          >
            {data.serviceName}
          </AnchorLink>
        ))}
        {/* <AnchorLink
          className={classes.serviceLinks}
          activeClass={classes.active}
          to="service-0"
          smooth={true}
          duration={500}
          spy={true}
          offset={-100}
        >
          Experiences
        </AnchorLink>
        <AnchorLink
          className={classes.serviceLinks}
          to="service-1"
          smooth={true}
          duration={500}
          spy={true}
          offset={-100}
        >
          Hair
        </AnchorLink>
        <AnchorLink
          className={classes.serviceLinks}
          to="service-2"
          smooth={true}
          duration={500}
          spy={true}
          offset={-100}
        >
          Shaves
        </AnchorLink>
        <AnchorLink
          className={classes.serviceLinks}
          to="service-3"
          smooth={true}
          duration={500}
          spy={true}
          offset={-100}
        >
          Other Services
        </AnchorLink> */}
      </Box>
      {/* <Tabs
        value={value}
        indicatorColor="primary"
        TabIndicatorProps={{
            style: {
              height:"0.25rem",
            }
        }}
        textColor="#000"
        centered
        variant="scrollable"
        onChange={handleChange}
        aria-label="Service-Tab"
        className={classes.serviceTab}
      >
        
          <Tab className={classes.serviceTabList} label="Experiences"><Link to="test" smooth={true} duration={500} />  </Tab>
        <Tab className={classes.serviceTabList} label="Hair" />
        <Tab className={classes.serviceTabList} label="Shaves" />
        <Tab className={classes.serviceTabList} label="Other Services" />
      </Tabs> */}
    </Paper>
  )
}

export default ServiceTab
