import React from 'react'
import { Paper } from '@material-ui/core'
import useStyles from './style'
const ServiceListWrapper = ({ children }) => {
  const classes = useStyles()
  return (
    <Paper className={classes.root} elevation={0}>
      {children}
    </Paper>
  )
}

export default ServiceListWrapper
