import React from 'react'
import { Box } from '@material-ui/core'
import Heroes from './Heroes'
import Layout from './Layout'
import Navbar from './Navbar'
import Footer from './Footer'
import ServiceTab from './ServiceTab'
import ServiceListWrapper from './ServiceListWrapper'
import ServiceList from './ServiceList'

import ServiceHair from '../../static/images/service-hair.jpg'
import ServiceExperiences from '../../static/images/service-experiences.jpg'
import ServiceShaves from '../../static/images/service-shaves.jpg'
import ServiceOther from '../../static/images/sketch-1.png'

import HeroesBg from '../../static/images/heroes-bg.png'

const datass = [
  {
    serviceName: 'Experiences',
    serviceDesc:
      'Using premium products, we are here to offer you the finest services that will elevate your grooming experience like never before, carried out by our highly-skilled barbers.',
    serviceImage: ServiceExperiences,
    serviceList: [
      {
        serviceTitle: 'Ultimate grooming experience',
        serviceInfo:
          'This experience will not only make you leave looking good, but also feeling good. Our barbers have all of your needs covered from a consultation to a refreshing hair wash, a relaxing face and back massage, a dapper hair cut, followed by a charcoal face mask as well as an ear shave and nose wax.',
      },
      {
        serviceTitle: 'Gentlemens grooming',
        serviceInfo:
          'Feel like a brand new person with our Gentlemen’s grooming experience, inclusive of a rejuvenating hair wash as well as a face and back massage. Our barbers will then give you a stylish and precise hair cut according to your desired look and will finish the experience with ear singeing.',
      },
      {
        serviceTitle: 'Ultimate shaving experience',
        serviceInfo:
          'With our three-step process using Proraso (from Italy) our shave is set to give you a natural, clean finish. The experience begins with a deep exfoliation facial scrub, followed by a pre-shave cream to prime and soften the stubble, then an expert facial shave, a cooling after-shave and finished with ear singeing.',
      },
      {
        serviceTitle: "Marco's Royal facial ",
        serviceInfo:
          'A fresh face is just as important as a fresh fade. Our facial starts with a deep exfoliation facial scrub to get rid of dead skin cells. Our second step uses a peel off activated charcoal mask to draw out dirt, toxins and oil from the skin. The experience is concluded with a hot steam to keep those pores clean and skin supple.',
      },
    ],
  },
  {
    serviceName: 'Hair',
    serviceDesc:
      'Your hair is in good hands. Trust our talented and well-trained barbers with an eye for style and detail to execute the perfect cut based on your most desired look.',
    serviceImage: ServiceHair,
    serviceList: [
      {
        serviceTitle: 'Just a Haircut',
        serviceInfo:
          'For those with a little less time at their disposal, this service offers grooming essentials of Haircut and Shampoo, all expertly performed by our highly trained team of barbers.',
      },
      {
        serviceTitle: 'Kids Cut',
        serviceInfo:
          'Let our professionally-trained barbers give your little lads a treat with a relaxing hair wash and a cool new hair do.',
      },
      {
        serviceTitle: 'Hair Coloring',
        serviceInfo:
          'If the salt&pepper look is not your flavor, then let our barbers transform your hair to your desired color.',
      },
      {
        serviceTitle: 'Head Shave',
        serviceInfo:
          'Our well-trained barbers will carry out a fully clean head shave efficiently with minimal redness or razor cuts, without compromising on being thorough and safe.',
      },
    ],
  },
  {
    serviceName: 'Shaves',
    serviceDesc:
      'An expert shave requires precision, patience and proficiency, which is done effortlessly by our barbers.',
    serviceImage: ServiceShaves,
    serviceList: [
      {
        serviceTitle: 'Hot towel Shaves',
        serviceInfo:
          'Pre-shave oil is applied with a luxurious hot towel wrap, followed by a rich shaving cream and finished with a close and comfortable shave.',
      },
      {
        serviceTitle: 'Moustache Trim',
        serviceInfo:
          'Keep that stache’ in place with precision trimming done by our well-trained barbers.',
      },
      {
        serviceTitle: 'Beard Trim',
        serviceInfo:
          'Having the right tools and skill set with attention to detail, our well-trained barbers are ready to give you the perfect beard trim, catering to each and every client’s desired look.',
      },
      {
        serviceTitle: 'Beard Coloring',
        serviceInfo:
          'Keep that beard sharp and dashing with your desired color.',
      },
    ],
  },
  {
    serviceName: 'Other Services',
    serviceDesc: 'We’ve got more pampering for you up our sleeves.',
    serviceImage: ServiceOther,
    serviceList: [
      {
        serviceTitle: 'Massages',
        serviceInfo:
          'Melt your stresses away and untie those knots by opting for our blissful head, neck and back massage, using aromatic oils. (Duration?)',
      },
      {
        serviceTitle: 'Face Scrub',
        serviceInfo:
          'Our deep exfoliating facial scrub gets rid of dead skin cells and picks up dirt in pores, making way for new, fresh and healthier skin.',
      },
      {
        serviceTitle: 'Charcoal Mask',
        serviceInfo:
          "Our activated charcoal draws bacteria, toxins, dirt and oil from the skin. If it's a peel off mask, it also works as an excellent exfoliator, ridding the skin of dead cells. The end result is skin that is clean and clear.",
      },
      {
        serviceTitle: 'Ear Singeing',
        serviceInfo:
          'Inspired by Turkish Barbers, It is the iconic ear flaming procedure that burns of excess hair that grows from your ears. After this, you’re all set for your Mrs to work her magic on your ears.',
      },
      {
        serviceTitle: 'Nose & Ear Waxing',
        serviceInfo:
          'Having warm wax in your ear does not sound too appealing, but the benefits are worth it. We guarantee that our ear hair waxing service removes all the hair, even the peach fuzz on the outside of your ear, in one shot. Depending on the growth of your hair, your ear can be hairless for four to six weeks.',
      },
    ],
  },
]

const Services = ({ pageContext }) => {
  let { banner, datas, footer } = pageContext
  let other
  for (let i = 0; i < datas.length; i++) {
    if (datas[i].serviceName == 'Other Services') {
      other = datas[i]
      datas.splice(i, 1)
    }
  }
  datas.push(other)
  const serviceLength = datas.length
  return (
    <Layout>
      <Navbar />
      <Heroes
        isServicePage
        minHeight="31.25rem"
        imgBackground={banner.gambarBanner.url}
        serviceHeading={banner.headingBanner}
        serviceDesc={banner.deskripsiBanner}
      />
      <ServiceTab datas={datas} />
      <ServiceListWrapper>
        {datas.map((data, index) => (
          <Box id={`service-${index}`} key={index}>
            <ServiceList
              serviceData={data}
              isLastElement={serviceLength - 1 === index}
            />
          </Box>
        ))}
        {/* {datas.map((data, index) => <ServiceList key={index}  serviceData={data} isLastElement={serviceLength-1 === index}/>)} */}
      </ServiceListWrapper>
      <Footer
        heading={footer.headingFooter}
        sosmed={[
          { text: footer.textFooter1, link: footer.linkFooter1 },
          { text: footer.textFooter2, link: footer.linkFooter2 },
          { text: footer.textFooter3, link: footer.linkFooter3 },
        ]}
        copyright={footer.copyright}
      />
    </Layout>
  )
}

export default Services
